export default {
  "landingpageLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["torna as compras online de comida, mercearia, entrega de encomendas e farmácia rápidas e fáceis. Receba mantimentos e peça seus alimentos favoritos dos melhores fornecedores."])},
  "landingpageHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tudo o que você precisa, entregue agora"])},
  "landingpageSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peça comida online, receba mantimentos e compre online com o melhor supermercado da sua região."])},
  "landingpageButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descubra fornecedores locais que entregam à sua porta"])},
  "landingpageJoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junte-se ao trem de negócios fácil"])},
  "landingpageJoinSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junte-se ao trem de negócios fácil e descubra um caminho mais simples para o sucesso. Embarque agora e experimente o empreendedorismo sem complicações"])},
  "landingpageSellerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torne-se um Vendedor"])},
  "landingpageSellerSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre-se como vendedor e abra uma loja para começar seu negócio hoje."])},
  "landingpageSellerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre-se"])},
  "landingpageSellerFeat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Várias opções de pagamento"])},
  "landingpageSellerFeat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fácil gerenciamento de pedidos"])},
  "landingpageSellerFeat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferecendo várias opções de pagamento"])},
  "landingpageSellerFeat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organização do início ao fim."])},
  "landingpageRide": {
    "landingpageRiderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torne-se um piloto conosco e ganhe mais"])},
    "landingpageRiderSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junte-se à nossa equipe como piloto e desbloqueie seu potencial de ganhos. Aproveite a liberdade de horários flexíveis e remuneração competitiva enquanto oferece felicidade aos clientes."])},
    "landingpageRiderButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torne-se um piloto"])}
  },
  "downloadPage": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilite a sua loja online com a nossa aplicação móvel"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplifique sua loja online com facilidade usando seu aplicativo móvel. Gerencie sua empresa em qualquer lugar e fique conectado com seus clientes a qualquer hora, em qualquer lugar"])}
  },
  "footer": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
    "sub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contate-nos"])},
    "sub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de Privacidade"])},
    "sub3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termos e Condições"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça negócios conosco"])},
    "sub4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisa de pilotos ou motoristas?"])},
    "sub5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quer vender em"])},
    "header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conta"])},
    "sub6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerenciar Conta"])},
    "sub7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos"])},
    "header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviços"])},
    "sub8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre uma loja"])},
    "header5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorias"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright"])},
    "copyright2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["todos os direitos reservados"])}
  },
  "cookieText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usamos cookies para melhorar sua experiência em nosso site. Ao navegar neste site, você concorda com o uso de cookies."])},
  "cookieButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitar"])},
  "nav": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])}
  },
  "pages": {
    "bestselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais Vendido"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O mais novo"])},
    "featured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apresentou"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pesquisar produtos"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar Resultados"])},
    "searchResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados da busca por"])},
    "searchResult3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum resultado encontrado"])},
    "searchResult4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum resultado encontrado para"])},
    "seeall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Tudo"])},
    "seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver Mais"])},
    "seeless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostre menos"])},
    "nearbyvendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornecedores Próximos"])},
    "foryou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para Você"])},
    "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornecedores Populares"])},
    "vendorSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontre os melhores fornecedores em sua área"])},
    "vendorSubHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temos todos os seus fornecedores favoritos aqui"])},
    "newArrivals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novas Chegadas"])},
    "newArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Chegada"])},
    "flashSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendas Relâmpago"])},
    "flashSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venda Rápida"])},
    "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livros"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livro"])},
    "todaysPicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolhas de Hoje"])},
    "noVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum Fornecedor"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolher Arquivo"])},
    "popularServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serviços Populares"])},
    "topRated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais Votado"])}
  },
  "Instock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em Estoque"])},
  "Outofstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fora de Estoque"])},
  "BestSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Seller"])},
  "hot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot"])},
  "product": {
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
    "addtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar ao carrinho"])},
    "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendado para você"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "optionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opções"])},
    "sameVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produtos do mesmo fornecedor"])},
    "optionSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione opções para adicioná-los ao produto"])}
  },
  "cart": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu Carrinho"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço"])},
    "totalProductPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço Total do Produto"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu carrinho está vazio"])},
    "empty2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu carrinho está vazio. Comece a comprar agora."])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "noExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum extra disponível"])},
    "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumo do pedido"])},
    "orderSummary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este subtotal não inclui o custo de entrega ou retirada"])},
    "totalItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Item"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subtotal"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço com Desconto"])},
    "coupon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desconto"])},
    "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montante Total"])},
    "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ter uma conta?"])},
    "useADiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use um código de desconto"])},
    "getDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obter Desconto"])},
    "shoppingWithSSLEncryption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras com criptografia SSL"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juntar"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "forAsmootherCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["para um checkout mais tranquilo"])},
    "continueToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkout"])}
  },
  "login": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
    "passwordDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sua senha deve conter pelo menos 8 caracteres, 1 maiúsculo, 1 letra minúscula e 1 número"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueceu sua Senha?"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail ou Senha está incorreto"])},
    "dontHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não tem uma conta?"])},
    "alreadyHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já tem uma conta?"])},
    "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criar uma conta"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem-vindo de volta a Kardi!"])},
    "SignUpLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie a sua conta aqui"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
    "checkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao Criar Conta, você concorda com nossa Política de Privacidade e Termos e Condições"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Completo"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de Telefone"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cidade"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEP"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme sua Senha"])}
  },
  "GroceryHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aqui, peça sua mercearia favorita de diferentes categorias"])},
  "pickupOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido de Retirada"])},
  "pharmacyIndicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indique se você retirará o pedido do fornecedor"])},
  "selectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione o endereço de entrega"])},
  "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métodos de Pagamento"])},
  "placeOrderRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Fazer Pedido de Pedido"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço de Entrega"])},
  "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar Endereço"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
  "orderReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O pedido estará pronto até"])},
  "parcel": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peça uma encomenda para recolher hoje"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quem pagar?"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remetente"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receptor"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envie encomendas ou pacotes para amigos e familiares."])},
    "modalTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatários do Pacote e Informações de Parâmetros"])},
    "modalSubTitile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatários do Pacote"])},
    "modalSubTitile2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parâmetros do Pacote"])},
    "trackPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rastreie sua encomenda"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peça Agora"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar"])},
    "recentOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedidos Recentes"])},
    "sendPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar Pacotes"])},
    "subHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu serviço de correio sob demanda local"])},
    "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpar Tudo"])},
    "packageTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipos de Pacote"])},
    "selectPackageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um Tipo de Pacote"])},
    "courierVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendedores de Correio"])},
    "selectCourierVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecione um Fornecedor de Correio"])},
    "deliveryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações Delivery"])},
    "pickupDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicione a coleta e o destino aqui"])},
    "fromLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
    "stopLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para onde ..."])},
    "pickupLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha um local de coleta"])},
    "dropoffLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha um local de entrega"])},
    "addStops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicionar paradas"])},
    "chooseTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha um tipo de pacote"])},
    "choosesCoourier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escolha um mensageiro"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Próximo"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
    "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatários"])},
    "recipientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações do Destinatário"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações de Contato"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notas"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ao confirmar que aceito que este pedido não contém itens ilegais/restritos, se itens ilegais/restritos forem encontrados por"])},
    "disclaimer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parceiro, eles podem denunciá-lo às autoridades policiais. Aplicam-se termos e condições."])},
    "packageParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Parâmetros do Pacote"])},
    "subHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preencha todos os parâmetros de pacote necessários aqui"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peso"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprimento"])},
    "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largura"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura"])},
    "deliveryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da Entrega"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickup"])},
    "dropoffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devoluções"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distância"])},
    "deliveryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxas de Entrega"])},
    "packageSizeCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cobranças por Tipo de Pacote"])},
    "recipientTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recebedor"])},
    "recipientEmptyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nome do destinatário é obrigatório"])},
    "recipientEmptyPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O número de telefone do destinatário é obrigatório"])}
  },
  "checkout": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como você gostaria de receber seu pedido?"])},
    "yourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu Pedido"])},
    "proceedToChcecout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fazer o check-out"])},
    "acceptedPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceitamos as seguintes opções de pagamento"])},
    "deliveryFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxa de entrega"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IMPOSTO"])},
    "driverTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gorjeta ao Motorista"])},
    "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faça a Encomenda"])}
  },
  "orders": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minhas Ordens"])},
    "accountSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurações de Conta"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você ainda não fez um pedido conosco. Quando você fizer isso, eles aparecerão aqui."])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produtos"])}
  },
  "profile": {
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações Pessoais"])},
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olá"])},
    "passwordChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mudança de Senha"])},
    "myFavourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus Favoritos"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha Antiga"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Senha"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirme sua Senha"])},
    "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar Senha"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
    "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar Perfil"])},
    "noAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você não tem Endereço"])}
  }
}